<template>
  <v-app>
    <v-container data-app>
      <v-card>
        <v-card-title>Новые клиенты</v-card-title>
        <v-card-actions>
          <DatePeriod @change="load" v-model="date"/>
        </v-card-actions>
      </v-card>

      <v-card>
        <v-card-text>
          <div ref="funnelContainer" style="background: #333333;padding: 20px;border-radius: 10px;">
            <vue-funnel-graph v-if="funnel"
                              ref="funnel"
                              :gradient-direction="'horizontal'"
                              :values="funnel.funnelValues"
                              animated
                              :colors="funnel.funnelColors"
                              :width="funnelWidth"
                              :labels="funnel.funnelLabels"
                              :sub-labels="funnel.funnelTypes"
                              :height="250"
            />
          </div>
          <v-simple-table v-if="funnel">
            <thead>
              <tr>
                <th>Источник</th>
                <th v-for="label in funnel.funnelLabels">{{label}}</th>
              </tr>
            </thead>
            <tbody>
            <tr v-for="(source, sourceIndex) in funnel.funnelTypes">
              <td>{{source}}</td>
              <td v-for="(label, funnelStage) in funnel.funnelLabels">
                {{funnel.funnelValues[funnelStage][sourceIndex]}}
                <v-chip v-if="funnelStage && funnel.funnelValues[funnelStage - 1][sourceIndex] && funnel.funnelValues[funnelStage][sourceIndex]/funnel.funnelValues[funnelStage - 1][sourceIndex]<1" class="px-1" color="white" small :style="'color:'+(funnel.funnelValues[funnelStage][sourceIndex]/funnel.funnelValues[funnelStage - 1][sourceIndex]<0.5 ? 'red' : '')">
                  {{ Math.round((funnel.funnelValues[funnelStage][sourceIndex]/funnel.funnelValues[funnelStage - 1][sourceIndex]-1) * 100) }}%
                </v-chip>
                <Percent v-if="funnelStage"/>
              </td>
            </tr>
            </tbody>
          </v-simple-table>
          <div v-if="funnel" class="reasons">
            <div class="reason"></div>
            <div class="reason" v-for="(reasons, stage) in funnel.rejectReasonsWithItems">
              <div v-for="(items, reason) in reasons">
                <div v-if="reason==='Всего'" style="margin-bottom: 5px;">
                    <span style="font-size: 16px;color: rgba(0, 0, 0, 0.87);">
                      Не перешло на этап
                      <span @click="openItemsModal(items, reason)" style="color: rgb(51, 153, 255);cursor: pointer">
                        {{ items.length }}
                      </span>
                      шт
                      <br>Причины:
                    </span>
                </div>
                <template v-else>
                  <span @click="openItemsModal(items, reason)" style="color: rgb(51, 153, 255);cursor: pointer"
                        class="mr-2">{{ items.length }}</span>
                  <span style="color: rgba(0, 0, 0, 0.87);">{{ reason }}</span>
                </template>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-container>
    <v-dialog data-app v-model="itemsModal.open">
      <v-card>
        <v-card-title v-text="itemsModal.title"/>
        <v-icon class="btn-close-modal" @click="itemsModal.open=false" size="36">mdi-close</v-icon>
        <v-card-text>
          <v-data-table
              :headers="itemsModal.items.length && itemsModal.items[0].item_type=='order' ? orderHeaders : leadHeaders"
              :items="itemsModal.items"
              item-key="id"
              :footer-props="{'items-per-page-options':[-1]}"
              no-data-text="Баланс пуст"
              loading-text="Загрузка..."
              mobile-breakpoint="0"
          >
            <template v-slot:item="{item}">
              <tr v-if="item.item_type == 'order'">
                <td>
                  <LeadSourceIcon :item="item"/>
                  <a :href="`/orders/${item.id}`" target="_blank">{{ item.id }}</a></td>
                <td>
                  <ClientLink style="display: block" :value="item.client"/>
                </td>
                <td>{{ $root.dateToRus(item.datetime_createObj.date) }} {{ item.datetime_createObj.time }}</td>
                <td>{{ $root.dateToRus(item.lastupdateObj.date) }} {{ item.lastupdateObj.time }}</td>
                <td>{{ $root.managerName(item.user_id) }}</td>
                <td style="white-space: nowrap">{{ $root.printCost(item.total) }}</td>
                <td>
                  {{ item.comment }}<br v-if="item.comment && item.comment_cancel"/>
                  {{ item.comment_cancel }}
                </td>
              </tr>
              <tr v-else-if="item.item_type == 'lead'">
                <td><a @click="$refs.lead_modal.open(item.id)">{{ item.id }}</a></td>
                <td>
                  <LeadContact :item="item"/>
                </td>
                <td>{{ $root.dateToRus(item.datetime_createObj.date) }} {{ item.datetime_createObj.time }}</td>
                <td>{{ $root.dateToRus(item.datetime_closedObj.date) }} {{ item.datetime_closedObj.time }}</td>
                <td>{{ $root.managerName(item.manager_closed) }}</td>
                <td>{{ item.message }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <LeadModal ref="lead_modal"/>
  </v-app>
</template>

<script>
import SavingParam from "../../components/SavingParam";
import DatePeriod from "../../components/DatePeriod";
import {VueFunnelGraph} from 'vue-funnel-graph-js';

import {Doughnut} from "vue-chartjs/legacy"
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  LineElement, CategoryScale, LinearScale, PointElement
} from 'chart.js'
import ChartJSPluginDatalabels from "chartjs-plugin-datalabels";
import ClientLink from "../../components/ClientLink.vue";
import LeadModal from "../leads/LeadModal.vue";
import LeadSourceIcon from "../../components/LeadSourceIcon.vue";
import LeadContact from "../../components/LeadContact.vue";
import Percent from "../../components/Percent.vue";

ChartJS.register(ChartJSPluginDatalabels, Title, Tooltip, Legend, ArcElement, CategoryScale, LineElement, LinearScale, PointElement)

export default {
  name: "NewClientsFunnel",
  components: {
    Percent,
    LeadContact,
    LeadSourceIcon,
    LeadModal,
    ClientLink,
    Doughnut,
    DatePeriod,
    SavingParam,
    VueFunnelGraph,
  },
  data() {
    return {
      stats: [],
      fired: [],
      orderHeaders: [
        {text: '№'},
        {text: 'Клиент'},
        {text: 'Дата создания'},
        {text: 'Дата последнего изменения'},
        {text: 'Оформил'},
        {text: 'Сумма'},
        {text: 'Коммент'},
      ],
      leadHeaders: [
        {text: '№'},
        {text: 'Клиент'},
        {text: 'Дата создания'},
        {text: 'Дата закрытия'},
        {text: 'Закрыл'},
        {text: 'Коммент'},
      ],
      columnActions: [],
      funnel: null,
      itemsModal: {
        open: false,
        title: '',
        items: [],
      },
      cleanerLists: {},
      date: [this.$moment().startOf('month').add(-1, 'month').format('YYYY-MM-DD'), this.$moment().endOf('month').add(-1, 'month').format('YYYY-MM-DD')],
      chartOptions: {
        responsive: true,
        cutout: 50,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            // display: false
          },
          datalabels: {
            color: '#fff',
            textAlign: 'center',
            font: {
              lineHeight: 1.3,
              size: 12,
              weight: 600
            },
            formatter: (value, ctx) => {
              return value > 0 ? `${ctx.chart.data.labels[ctx.dataIndex]} ${value}` : '';
            }
          }
        }
      },
      statMode: 0,
      funnelWidth: 600,
    }
  },
  methods: {
    load() {
      this.$store.state.server.request('leads/newClientsFunnel', {date: this.date}, (data) => {
        this.funnelWidth = this.$refs.funnelContainer.offsetWidth - 40;
        this.funnel = data.response;
        this.fired = data.fired;
        this.columnActions = data.columnActions;
        this.cleanersList = data.cleaners;
      })
    },
    printPercent(current, prev) {
      return '<span></span>';
    },
    openItemsModal(items, title) {
      this.itemsModal.open = true;
      this.itemsModal.items = items;
      this.itemsModal.title = title;
    },
  },
  mounted() {
    this.load();
  },
  created() {
    this.$eventBus.$on('funnelReload', () => this.load())
  },
  destroyed() {
    this.$eventBus.$off('funnelReload');
  },
}
</script>
<style scoped lang="scss">
.reasons {
  display: flex;
  margin-top: 20px;

  .reason {
    flex: 1;
  }
}
</style>